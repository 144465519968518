(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$window', '$rootScope', '$state', '$uibModal', 'Auth', 'Principal', 'ProfileService',
        'LoginService', 'Mensagem', '$http', 'Parceiro', '$timeout', 'ConfirmService', 'User', 'Tutorial', '$location', '$sce'];

    function NavbarController($window, $rootScope, $state, $uibModal, Auth, Principal, ProfileService,
        LoginService, Mensagem, $http, Parceiro, $timeout, ConfirmService, User, Tutorial, $location, $sce) {
        var vm = this;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        vm.trustedHtml = trustedHtml;
        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.getPessoas = getPessoas;
        vm.selectPessoa = selectPessoa;
        vm.irParaDashboard = irParaDashboard;
        vm.abrirTutorial = abrirTutorial;
        vm.parceirosAdmins = [];
        vm.$state = $state;

        vm.error = null;
        vm.isLoading = false;
        vm.settingsAccount = null;
        vm.success = null;
        vm.solicitarExclusao = solicitarExclusao;

        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
            });
        }

        carregarParceiro();

        $rootScope.$on('mySkillsPersonalApp:mensagemUpdate', function () {
            carregarMensagensNaoLidas();
        });

        $rootScope.$on('mySkillsPersonalApp:mensagemUpdateNavBar', function () {
            carregarMensagensNaoLidas();
        });

        function carregarTutorial(toState) {
            toState = toState ? toState : vm.$state.current;
            vm.tutorial = null;
            Tutorial.getPorPagina({pagina: toState.name}, function (data) {
                vm.tutorial = data;
            });
        }

        carregarTutorial();

        $rootScope.$on('$stateChangeSuccess',
            function (event, toState) {
                carregarTutorial(toState);
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });

        function abrirTutorial() {
            $uibModal.open({
                templateUrl: 'app/layouts/navbar/tutorial.html',
                size: 'lg',
                controller: 'TutorialDialogController',
                controllerAs: 'vm',
                resolve: {
                    entity: function () {
                        return vm.tutorial;
                    }
                }
            });
        }

        function carregarMensagensNaoLidas() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account) {
                    Mensagem.totalNaoLidas({}, function (data) {
                        vm.totalNaoLidas = data.naoLidas;
                    });
                    carregarAdministradoresParceiros();
                }
            });
        }

        function carregarAdministradoresParceiros() {
            if (vm.isAuthenticated) {
                Parceiro.getAdiministradoresParceiro({}, function (admins) {
                    vm.parceirosAdmins = admins;
                });
            }
        }

        carregarMensagensNaoLidas();


        function irParaDashboard() {
            angular.forEach(vm.parceirosAdmins, function (a) {
                $state.go('parceiro-detail', {id: a.idParceiro});
            });
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home', null, {reload: true});
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function getPessoas(param) {
            if (!vm.isAuthenticated()) {
                param = param.match(/\d+/g).map(Number);
            }
            return $http.get('api/_search/pessoas/', {
                params: {
                    query: param,
                    page: 0,
                    size: 10,
                }
            }).then(function (response) {

                return response.data.map(function (pessoa) {
                    return pessoa;
                });
            });
        }

        function selectPessoa($item, $model, $label, $event) {
            if ($model.id) {
                $state.go("pessoa-detail", {id: $model.id});
            }
        }


        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };

        Principal.identity().then(function (account) {
            if (account)
                vm.settingsAccount = copyAccount(account);
        });

        function successfulDeletionAlert() {
            ConfirmService.swal({
                    title: "Conta excluída com sucesso!",
                    text: " ",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "OK",
                    closeOnConfirm: true,
                    showCancelButton: false,
                },
                function (isConfirm) {
                    if (isConfirm) {
                        Auth.logout();
                        $state.go('login');
                    }
                });
        }

        function solicitarExclusao() {
            ConfirmService.swal({
                    title: "Tem certeza que deseja excluir sua conta?",
                    text: "Esta operação não poderá ser desfeita!",
                    type: "warning",
                    cancelButtonText: "Cancelar",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim, pode deletar!",
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.isLoading = true;
                        User.deleteByUser({login: vm.settingsAccount.login}, onSaveSuccess, onSaveError);
                    }
                });
        }

        function onSaveSuccess(result) {
            vm.isLoading = false;
            $timeout(function () {
                successfulDeletionAlert();
            }, 1000);
        }

        function onSaveError() {
            vm.isLoading = false;
            vm.success = null;
            vm.error = 'ERROR';
        }
    }
})();
